import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksThreeColImageOneColText,
  SettingsPartsFragment,
} from '@generated/types';

export default function ThreeColImageOneColText({
  data,
  global,
  settings,
  site
}: {
  data: PageBlocksThreeColImageOneColText;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  return (
    <div className="w-full h-20 border rounded p-4 flex items-center justify-center">
      ThreeColImageOneColText
    </div>
  );
}
ThreeColImageOneColText.blockName = 'PageBlocksThreeColImageOneColText';
