import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksSpace,
  SettingsPartsFragment,
} from '@generated/types';
import { useEffect, useState } from 'react';

export default function Space({
  data,
}: {
  data: PageBlocksSpace;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const { xs, sm, md, lg, xl, hd, twoK } = data;

  const [screenWidth, setScreenWidth] = useState(0);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getHeightForScreen = () => {
    if (screenWidth >= 1920) {
      return twoK;
    }
    if (screenWidth >= 1536) {
      return hd;
    }
    if (screenWidth >= 1280) {
      return xl;
    }
    if (screenWidth >= 1024) {
      return lg;
    }
    if (screenWidth >= 768) {
      return md;
    }
    if (screenWidth >= 640) {
      return sm;
    }
    return xs;
  };

  return (
    <section className={`section-space`}>
      <div
        style={{
          height: `${getHeightForScreen()}px`,
        }}
      />
    </section>
  );
}

Space.blockName = 'PageBlocksSpace';
