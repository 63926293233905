import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksFourColImages,
  SettingsPartsFragment,
} from '@generated/types';

export default function FourColImages({
  data,
  global,
  settings,
  site
}: {
  data: PageBlocksFourColImages;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  return (
    <div className="w-full h-20 border rounded p-4 flex items-center justify-center">
      FourColImages
    </div>
  );
}
FourColImages.blockName = 'PageBlocksFourColImages';
