import { TinaField } from 'tinacms';
import { StyledGroup } from './conditional-component';

export enum TwitterCardOptions {
  Summary = 'summary',
  SummaryLargeImage = 'summary_large_image',
  App = 'app',
  Player = 'player',
}

export const meta: TinaField = {
  type: 'object',
  name: 'meta',
  label: 'Meta Tags',
  ui: {
    component: StyledGroup as any,
  },
  fields: [
    {
      type: 'string',
      label: 'Author',
      name: 'author',
    },
    {
      // @ts-ignore
      type: 'string',
      list: true,
      name: 'keywords',
      // @ts-ignore
      component: 'tags',
      label: 'Keywords',
    },
    {
      type: 'string',
      name: 'ogType',
      label: 'OG:Type',
    },
    {
      type: 'image',
      name: 'ogImage',
      description:
        'Cover image for embeds in Social Media. Recommended resolution: 1200:1200px',
      label: 'OG:Image',
    },
    {
      type: 'string',
      name: 'ogTitle',
      label: 'OG:Title',
    },
    {
      type: 'string',
      name: 'ogDescription',
      label: 'OG:Description',
      description: 'Should be greater than 80 and smaller than 160 characters',
    },
    {
      type: 'string',
      name: 'ogSiteName',
      label: 'OG:Site Name',
    },
    {
      type: 'string',
      name: 'twitterCard',
      label: 'Twitter:Card',
      options: Object.values(TwitterCardOptions),
    },
    {
      type: 'string',
      name: 'twitterSite',
      label: 'Twitter:Site',
      description:
        'Your Twitter username with the @, by default -  @OPT_Industries',
    },
    {
      type: 'string',
      name: 'twitterTitle',
      label: 'Twitter:Title',
    },
    {
      type: 'string',
      name: 'twitterDescription',
      label: 'Twitter:Description',
    },
  ],
};
