import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksTeamSlider,
  SettingsPartsFragment,
} from '@generated/types';

export default function TeamSlider({
  data,
  site
}: {
  data: PageBlocksTeamSlider;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  if (data?.entries && data?.entries?.length > 3) {
    let entries = data.entries;

    return (
      <section className="section-teamslider w-full border rounded p-4 flex items-center justify-center relative">
        {entries.map((entry, index) => {
          if (entry) {
            return (
              <div key={`team-slider#${index}`}>
                <img src={entry.image ?? ''} alt={entry.entryHeadline ?? ''} />
                <h3>{entry.entryHeadline}</h3>
                <p>{entry.entryBody}</p>
              </div>
            );
          }
          return null;
        })}
      </section>
    );
  }
}
TeamSlider.blockName = 'PageBlocksTeamSlider';
