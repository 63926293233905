// ContactForm.tsx
'use client';

import { useEffect, useTransition } from 'react';
import { useFormState } from 'react-dom';

import { FieldValues, SubmitHandler } from 'react-hook-form';

// import HubSpotForm, { FieldGroup } from '@ez-digital/react-hubspot-hook-form';
import '@ez-digital/react-hubspot-hook-form/style';
import HubSpotForm, { FieldGroup } from './CustomForm';

import { PageBlocksContactForm } from '@generated/types';
import { tinaField } from 'tinacms/dist/react';
import type { Shape } from '../../../tina/blocks/utils';
import {
  getFontWeightClass,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '../../utils/Templates/style';
import { ContactFormSubmit } from './actions';

export type State = {
  status: 'success' | 'error';
  message?: string;
} | null;

const ContactFormBase = ({
  fieldGroups,
  submitButtonText,
  portalId,
  contactFormId,
  shapesOverrides,
  data,
}: {
  fieldGroups: FieldGroup[];
  submitButtonText: string;
  portalId: string;
  contactFormId: string;
  shapesOverrides?: Shape[];
  data: PageBlocksContactForm;
}) => {
  const [isSubmitting, startTransition] = useTransition();

  const ContactFormSubmitWithProps = ContactFormSubmit.bind(
    null,
    portalId,
    contactFormId,
  );

  const [message, formAction] = useFormState<State, FieldValues>(
    ContactFormSubmitWithProps,
    null,
  );

  useEffect(() => {
    if (message?.status === 'success') {
      console.log('form submitted successfully');
    }
  }, [message]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    startTransition(() => {
      formAction(data);
    });
  };

  const headlineParams = transformToHeadlineParams(data?.headline);
  const HeadlineTag = getHeadlineTagFromData(headlineParams);
  const fontWeightClass = getFontWeightClass(headlineParams);
  const bodyParams = transformToHeadlineParams(data?.body);
  const bodyFontWeightClass = getFontWeightClass(bodyParams);
  return (
    <div className="w-full contentGrid !grid-cols-2 md:!grid-cols-4">
      <div className="flex flex-col col-span-2 md:col-span-1  space-y-4">
        {/* Headline */}
        {data?.headline?.text && (
          <HeadlineTag
            className={fontWeightClass}
            data-tina-field={tinaField(data, 'headline')}
          >
            {data.headline.text}
          </HeadlineTag>
        )}
        {data?.body?.text && (
          <p
            className={bodyFontWeightClass}
            data-tina-field={tinaField(data, 'body')}
          >
            {data.body.text?.split('\n').map((line, index) => (
              <span key={index}>
                {line}
                <br />
              </span>
            ))}
          </p>
        )}
      </div>
      <div className="hidden md:block md:col-span-1"></div>
      <div className="col-span-2 mt-8 md:mt-0">
        <HubSpotForm
          fieldGroups={fieldGroups}
          submitButtonText={submitButtonText}
          data={data}
          onSubmit={onSubmit}
          fieldClassName="text-primary-foreground"
          fieldContolClassName=""
          isSubmitting={isSubmitting}
          isSubmitted={message?.status === 'success'}
          successMessage="The form has been submitted successfully."
        />
      </div>
    </div>
  );
};

export default ContactFormBase;
