'use client';
import { Site } from '@apps/site';
import { PageBlocksTwoColImageTwoColText } from '@generated/types';
import SubMenuSingle from '@libs/ui/utils/SubMenuSingle';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getFontSizeClass,
  getFontWeightClass,
  getHeadlineTag,
  HeadlineParams,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import AnyMedia from '../../utils/any-media';
import { SafeLink } from '../../utils/safe-link';

function getClassFromParams(params: HeadlineParams) {
  const tag = getHeadlineTag(params);
  const fontWeightClass = params.fontWeight
    ? getFontWeightClass({ fontWeight: params.fontWeight })
    : '';
  const fontSizeClass = getFontSizeClass({ fontSize: params.fontSize });

  return {
    tag,
    fontWeightClass,
    fontSizeClass,
  };
}

export default function TwoColImageTwoColText({
  data,
  site,
}: {
  data: PageBlocksTwoColImageTwoColText;
  site: Site;
}) {
  const headlineParams = transformToHeadlineParams(data?.headline);
  const bodyParams = transformToHeadlineParams(data?.body);
  const boxShadow = data?.boxShadow;
  const boxShadowBig = boxShadow ? 'box-shadow-big' : '';
  const { tag: HeadlineTag, fontWeightClass: headlineClass } =
    getClassFromParams(headlineParams);
  const {
    tag: BodyTag,
    fontWeightClass,
    fontSizeClass,
  } = getClassFromParams(bodyParams);
  const bodyClass = `${fontWeightClass} ${fontSizeClass}`;
  const {
    fullHeight = false,
    marginTop = false,
    marginBottom = false,
  } = data || {};

  // Apply dynamic classes based on settings
  const containerClasses = `
    ${fullHeight ? 'fullscreen' : 'h-auto'}
    flex
    items-center
    justify-center
    ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
    ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
  `;
  const layoutClass =
    data?.layout === 'Image Left Text Right'
      ? 'flex-col md:flex-row'
      : 'flex-col-reverse md:flex-row';
  const [visibleMenu, setVisibleMenu] = useState<number | null>(null); // State to track which submenu is visible

  return (
    <section className={`section-twocol-imagetext w-full ${containerClasses}`}>
      <div className={`h-auto container flex ${layoutClass} items-center`}>
        <div className="grid contentGrid w-full">
          {data?.media && (
            /*
             */
            <div
              className={`col-span-4 relative md:col-span-2 ${data?.layout === 'Image Left Text Right' ? 'md:order-1' : 'md:order-2'} mt-4 md:mt-0`}
            >
              <span
                className="opacity-0 data-[selected=true]:opacity-100 transition-opacity duration-500"
                data-selected={
                  visibleMenu === null || !data.entries?.[visibleMenu]?.media
                }
              >
                <AnyMedia
                  source={
                    data?.useEmbeddedLink
                      ? data.embedUrl || data.media
                      : data.media
                  }
                  alt="Two Column Layout Image"
                  tina={tinaField(data, 'media')}
                  className={`relative w-full  object-cover columnImage ${boxShadowBig} ${data.variant === 'Large' ? 'h-[350px] xl:h-[500px] hd:h-[800px] 2k:h-[1100px]' : 'h-[250px] xl:h-[300px] hd:h-[450px] 2k:h-[600px]'}`}
                />
              </span>
              {data.entries?.map(
                (entry, index) =>
                  entry?.media && (
                    <span
                      data-selected={visibleMenu === index}
                      className="opacity-0 data-[selected=true]:opacity-100 transition-opacity duration-500 absolute top-0 left-0 right-0 bottom-0 w-full"
                    >
                      <AnyMedia
                        source={
                          entry?.useEmbeddedLink
                            ? entry.embedUrl || entry.media
                            : entry.media
                        }
                        alt={'Image for: ' + entry.fieldText}
                        tina={tinaField(data, 'media')}
                        className={`absolute top-0 left-0 right-0 bottom-0 object-cover columnImage ${data.variant === 'Large' ? 'h-[350px] xl:h-[500px] hd:h-[800px] 2k:h-[1100px]' : 'h-[250px] xl:h-[300px] hd:h-[450px] 2k:h-[600px]'}`}
                      />
                    </span>
                  ),
              )}
            </div>
          )}

          <div
            className={`flex flex-col justify-center col-span-4 md:col-span-2 mt-8 md:mt-0 ${data?.layout === 'Image Left Text Right' ? 'md:order-2' : 'md:order-1'}`}
          >
            {data?.headline?.text && (
              <div className="flex items-center justify-between mb-4">
                <HeadlineTag
                  className={`${headlineClass}`}
                  data-tina-field={tinaField(data, 'headline')}
                >
                  {data.headline.text}
                </HeadlineTag>
              </div>
            )}

            {data?.body?.text && (
              <BodyTag
                className={`${bodyClass}`}
                data-tina-field={tinaField(data, 'body')}
              >
                {data.body.text}
              </BodyTag>
            )}

            {data?.entries && data.entries?.length > 0 && (
              <div className="col-span-4" data-tina-field={tinaField(data)}>
                <SubMenuSingle
                  data={data?.entries}
                  hideTopDots
                  {...{ visibleMenu, setVisibleMenu }}
                />
              </div>
            )}

            {data?.linkButton?.buttonText && data?.linkButton?.link && (
              <SafeLink
                href={data.linkButton.link}
                className="mt-4 py-3 text-text_color flex items-center"
                tina={tinaField(data, 'linkButton')}
              >
                <span>{data.linkButton.buttonText}</span>
                <div className="shapesOuter ml-[1rem] md:ml-[40px]">
                  <SubmenuSingleIcon
                    width={15}
                    height={25}
                    className="text-text_color"
                  />
                </div>
              </SafeLink>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

TwoColImageTwoColText.blockName = 'PageBlocksTwoColImageTwoColText';
