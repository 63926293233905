import { Site } from '@apps/site';
import { PageBlocksTeaserTextLarge } from '@generated/types';
import { convertRichTextToHTML, getRichText } from '@libs/tina/utils';
import { tinaField } from 'tinacms/dist/react';

export default function TeaserTextLarge({
  data,
  site,
}: {
  data: PageBlocksTeaserTextLarge;
  site: Site;
}) {
  const richText = data.richText || getRichText('No content available');

  const richFormattedText = convertRichTextToHTML(richText);
  const { fullHeight = false, marginTop = false, marginBottom = false } = data || {};

  // Apply dynamic classes based on settings
  const containerClasses = `
    ${fullHeight ? 'fullscreen' : 'h-auto'}
    flex
    items-center
    justify-center
    ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
    ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
  `;
  return (
    <section className={`section-teaser-text-large container ${containerClasses}`}>
      <div className="grid contentGrid">
        {richFormattedText ? (
          <div
            data-tina-field={tinaField(data, 'richText')}
            className="text-left col-span-4 p-4"
            dangerouslySetInnerHTML={{ __html: richFormattedText }}
          ></div>
        ) : (
          'No content available'
        )}
      </div>
    </section>
  );
}

TeaserTextLarge.blockName = 'PageBlocksTeaserTextLarge';
