import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksContactForm,
  SettingsPartsFragment,
} from '@generated/types';
import { useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { getHubSpotForm } from './actions';
import ContactFormBase from './ContactFormBase';

export default function ContactForm({
  data,
  global,
  settings,
  site,
}: {
  data: PageBlocksContactForm;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const [formData, setFormData] = useState<any | null>(null);

  useEffect(() => {
    (async () => {
      if (data.contactFormId && data.portalId) {
        const formData = await getHubSpotForm(data.contactFormId);
        setFormData(formData);
      } else {
        setFormData(null);
      }
    })();
  }, [data.contactFormId, data.portalId]);

  return (
    <section className="section-contact-form container" data-tina-field={tinaField(data)}>
      {formData ? (
        <ContactFormBase
          {...formData}
          contactFormId={data.contactFormId}
          portalId={data?.portalId}
          shapesOverrides={data?.shapesOverrides}
          data={data}
        />
      ) : data.portalId && data?.contactFormId ? (
        <div>Loading...</div>
      ) : (
        <></>
      )}
      {!data?.contactFormId && (
        <div data-tina-field={tinaField(data, 'contactFormId')}>
          Contact form ID is not configured
        </div>
      )}
      {!data.portalId && (
        <div data-tina-field={tinaField(data, 'portalId')}>
          Portal ID is not configured
        </div>
      )}
    </section>
  );
}
ContactForm.blockName = 'PageBlocksContactForm';
