export * from './meta';

export function getRichText(t: string) {
  return {
    type: 'root',
    children: [{ type: 'p', children: [{ type: 'text', text: t }] }],
  };
}

export function getPlainText(t: JSON): string {
  return ((t as any)?.children?.[0]?.children?.[0]?.text as string) || '';
}

export function convertToTitleCase(str?: string): string | undefined {
  return str
    ?.split('-') // Split the string by the hyphen
    ?.map(
      (word) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase(),
    ) // Capitalize the first letter of each word
    ?.join(' '); // Join the words with a space
}

export function convertRichTextToHTML(richText: any): string {
  if (!richText || !richText.children) return '';

  const extractText = (node: any): string => {
    if (node.type === 'text') {
      let textContent = node.text;

      // Check if the text is wrapped in '**' for bold
      if (textContent.startsWith('**') && textContent.endsWith('**')) {
        textContent = `<strong>${textContent?.slice(2, -2).trim()}</strong>`; // Remove '**' and wrap in <strong>
      } else if (node.bold) {
        // Check if the bold property is true
        textContent = `<strong>${textContent}</strong>`;
      }

      return textContent;
    } else if (node.type === 'break') {
      return '<br/>';
    } else {
      const childContent = node.children
        ? node.children.map(extractText).join('')
        : '';
      return `<${node.type}>${childContent}</${node.type}>`;
    }
  };

  return richText.children.map(extractText).join('');
}
