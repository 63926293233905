'use client';
import Image from 'next/image';

interface AnyMediaProps {
  source: string;
  className?: string;
  alt?: string;
  tina?: any;
  width?: number;
  height?: number;
}

const AnyMedia = ({
  source,
  className,
  alt,
  tina,
  width,
  height,
}: AnyMediaProps) => {
  // Determine the type of the source (YouTube, image, HLS, DASH, MP4)
  const getSourceType = (source: string) => {
    if (source.includes('youtube.com') || source.includes('youtu.be')) {
      return 'youtube' as const;
    } else if (source.includes('.mp4')) {
      return 'mp4' as const;
    } else if (/\.(jpg|jpeg|png|gif|webp)/.test(source)) {
      return 'image' as const;
    }
    return 'image' as const;
  };

  // Parse width and height from query parameters in the imageUrl
  const parseImageSizeFromUrl = (url: string) => {
    const urlObj = new URL(url, 'http://example.com');
    const width = urlObj.searchParams.get('w');
    const height = urlObj.searchParams.get('h');
    return { width, height };
  };

  const sourceType = getSourceType(source);

  // Render YouTube, Image, or Video based on detected source type
  if (sourceType === 'youtube') {
    const videoId = source.includes('v=')
      ? source.split('v=')[1]
      : source.split('/').pop();
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
      <div
        className={`youtube-embed-container ${className}`}
        data-tina-field={tina}
      >
        <iframe
          width="100%"
          height="100%"
          src={embedUrl}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="YouTube Video"
        ></iframe>
      </div>
    );
  }

  if (sourceType === 'image') {
    const { width: w, height: h } = parseImageSizeFromUrl(source);

    return (
      <div className={`image-container ${className}`} data-tina-field={tina}>
        {width && height ? (
          <Image
            src={source}
            alt={alt || 'Media'}
            width={Number(w || width)}
            height={Number(h || height)}
          />
        ) : (
          <Image
            src={source}
            alt={alt || 'Media'}
            width={width || undefined}
            height={height || undefined}
            fill
            style={{ objectFit: 'cover' }}
          />
        )}
      </div>
    );
  }

  if (sourceType === 'mp4') {
    return (
      <div className={className} data-tina-field={tina}>
        <video
          className="w-full h-full object-cover"
          playsInline
          autoPlay
          loop
          muted
          src={source}
        />
      </div>
    );
  }

  return (
    <div className={className} data-tina-field={tina}>
      Selected media type is not supported
    </div>
  );
};

export const AnyMediaRichText = ({
  source,
  className,
  alt,
  tina,
}: AnyMediaProps) => {
  // Determine the type of the source (YouTube, image, HLS, DASH, MP4)
  const getSourceType = (source: string) => {
    if (source.includes('youtube.com') || source.includes('youtu.be')) {
      return 'youtube' as const;
    } else if (source.includes('.mp4')) {
      return 'mp4' as const;
    } else if (/\.(jpg|jpeg|png|gif|webp)/.test(source)) {
      return 'image' as const;
    }
    return 'image' as const;
  };

  // Parse width and height from query parameters in the imageUrl
  const parseImageSizeFromUrl = (url: string) => {
    const urlObj = new URL(url, 'http://example.com');
    const width = urlObj.searchParams.get('w');
    const height = urlObj.searchParams.get('h');
    return { width, height };
  };

  const sourceType = getSourceType(source);

  // Render YouTube, Image, or Video based on detected source type
  if (sourceType === 'youtube') {
    const videoId = source.includes('v=')
      ? source.split('v=')[1]
      : source.split('/').pop();
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;

    return (
      <iframe
        width="100%"
        height="100%"
        src={embedUrl}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube Video"
        className={`youtube-embed-container ${className}`}
        data-tina-field={tina}
      ></iframe>
    );
  }

  if (sourceType === 'image') {
    const { width, height } = parseImageSizeFromUrl(source);

    return (
      <>
        {width && height ? (
          <Image
            src={source}
            alt={alt || 'Media'}
            width={Number(width)}
            height={Number(height)}
            className={`image-container relative block w-full ${className}`}
            data-tina-field={tina}
          />
        ) : (
          <Image
            src={source}
            alt={alt || 'Media'}
            fill
            style={{ objectFit: 'cover', width: '100%', maxHeight: 400 }}
            className={`image-container relative block w-full ${className}`}
          />
        )}
      </>
    );
  }

  if (sourceType === 'mp4') {
    return (
      <span className={'block w-full ' + className} data-tina-field={tina}>
        <video
          className="w-full h-full object-cover"
          playsInline
          autoPlay
          loop
          muted
          src={source}
        />
      </span>
    );
  }

  return (
    <span className={'block w-full ' + className} data-tina-field={tina}>
      Selected media type is not supported
    </span>
  );
};

export default AnyMedia;
