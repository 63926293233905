import { Site } from '@apps/site';
import { PageBlocksHistoryText } from '@generated/types';
import { FontSize, FontWeight, Sides } from '@libs/tina/blocks/utils';
import {
  getFontSizeClass,
  getFontWeightClass,
  getHeadlineTag,
  isFontSize,
  isFontWeight,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';
import ShapesComponent from '../../shared/shapes';
export default function HistoryText({
  data,
  site,
}: {
  data: PageBlocksHistoryText;
  site: Site;
}) {
  return (
    <section
      className="w-full flex flex-col container section-history-text"
      data-tina-field={tinaField(data)}
    >
      {data?.entries &&
        data.entries.length > 0 &&
        data.entries.map((entry, index) => {
          const entryStyle = data.entryStyle || {};
          const headlineStyle = entryStyle.headline || {};
          const bodyStyle = entryStyle.body || {};

          const headlineFontSize = isFontSize(headlineStyle.fontSize ?? '')
            ? headlineStyle.fontSize
            : null;
          const headlineFontWeight = isFontWeight(
            headlineStyle.fontWeight ?? '',
          )
            ? headlineStyle.fontWeight
            : null;
          const bodyFontSize = isFontSize(bodyStyle.fontSize ?? '')
            ? bodyStyle.fontSize
            : null;
          const bodyFontWeight = isFontWeight(bodyStyle.fontWeight ?? '')
            ? bodyStyle.fontWeight
            : null;

          const headlineParams = {
            fontSize: headlineFontSize as FontSize | null,
            fontWeight: headlineFontWeight as FontWeight | null,
          };
          const bodyParams = {
            fontSize: bodyFontSize as FontSize | null,
            fontWeight: bodyFontWeight as FontWeight | null,
          };

          const HeadlineTag = getHeadlineTag(headlineParams);
          const BodyTag = getHeadlineTag(bodyParams);
          const fontWeightClass = getFontWeightClass(headlineParams);
          const bodyFontWeightClass = getFontWeightClass(bodyParams);
          const bodyFontSizeClass = getFontSizeClass(bodyParams);

          // Determine layout based on variant
          const isRightVariant = entry?.variant === Sides.Right;

          return (
            <div
              key={index}
              className={`contentGrid grid !grid-cols-2 md:!grid-cols-4 mb-4 grid-flow-dense`}
            >
              {/* Year */}
              <div
                className={`flex items-start justify-start col-span-2 font-extralight text-[12rem] relative ${isRightVariant ? 'md:col-start-3 md:text-end' : ''}`}
                data-tina-field={tinaField(entry, 'year')}
              >
                <span className="leading-none mb-12">{entry?.year ?? ''}</span>
                {!isRightVariant && (
                  <div
                    data-tina-field={tinaField(entry, 'shapeOptions')}
                    className="absolute top-0 right-0"
                  >
                    <ShapesComponent shapeOptions={entry?.shapeOptions || {}} />
                  </div>
                )}
              </div>

              {/* Entry Content */}
              <div
                className={`col-span-2 relative ${isRightVariant ? 'md:col-start-1' : ''}`}
              >
                {/* Entry Headline */}
                {entry?.entryHeadline && (
                  <HeadlineTag
                    className={`${fontWeightClass} mt-1`}
                    data-tina-field={tinaField(entry, 'entryHeadline')}
                  >
                    {entry.entryHeadline}
                  </HeadlineTag>
                )}

                {/* Entry Body */}
                {entry?.entryBody && (
                  <BodyTag
                    className={`${bodyFontWeightClass} ${bodyFontSizeClass} mt-8`}
                    data-tina-field={tinaField(entry, 'entryBody')}
                  >
                    {entry.entryBody}
                  </BodyTag>
                )}
                {isRightVariant && (
                  <div
                    data-tina-field={tinaField(entry, 'shapeOptions')}
                    className="absolute top-0 right-0"
                  >
                    <ShapesComponent shapeOptions={entry?.shapeOptions || {}} />
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </section>
  );
}

HistoryText.blockName = 'PageBlocksHistoryText';
