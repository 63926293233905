import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksDownloadList,
  SettingsPartsFragment,
} from '@generated/types';

import { Shape } from '@libs/tina/blocks/utils';
import {
  Circle,
  HalfsquareDown,
  HalfsquareLeft,
  HalfsquareRight,
  HalfsquareUp,
  Square,
} from '@libs/ui/utils/Templates/Shapes';

export default function DownloadList({
  data,
  site
}: {
  data: PageBlocksDownloadList;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  if (data?.listEntries) {
    return (
      <section className="section-download-list w-full h-20 border rounded p-4 flex items-center justify-center relative">
        {data.listEntries.map((entry, index) => {
          if (entry) {
            return (
              <div key={`list-entry#${index}`}>
                <h3>{entry.entryHeadline}</h3>
                <p>{entry.entryBody}</p>
                {entry.shape === Shape.Square && <Square />}
                {entry.shape === Shape.Circle && <Circle />}
                {entry.shape === Shape.Left && <HalfsquareLeft />}
                {entry.shape === Shape.Right && <HalfsquareRight />}
                {entry.shape === Shape.Up && <HalfsquareUp />}
                {entry.shape === Shape.Down && <HalfsquareDown />}
                {entry.shape === Shape.Empty && <></>}
              </div>
            );
          }
          return null;
        })}
      </section>
    );
  }
}
DownloadList.blockName = 'PageBlocksDownloadList';
