import { Site } from '@apps/site';
import { PageBlocksCategorySlider } from '@generated/types';
import { Slider } from '@libs/ui/shared/slider';
import SvgComponent from '@libs/ui/utils/Templates/Shapes/headline_link_arrow_icon';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getFontWeightClass,
  getHeadlineTag,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import React, { UIEvent, useEffect, useRef, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../../utils/safe-link';

export default function CategorySlider({
  data,
  site,
}: {
  data: PageBlocksCategorySlider;
  site: Site;
}) {
  const [sliderValue, setSliderValue] = useState([0]);
  const [sliderMobileValue, setSliderMobileValue] = useState([0]);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerMobileWidth, setContainerMobileWidth] = useState(0);
  const [columnWidth, setColumnWidth] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const containerMobileRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const scrollContainerMobileRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current?.clientWidth;
        setColumnWidth(containerWidth / 2);
      }
      if (containerMobileRef.current) {
        setContainerMobileWidth(containerMobileRef.current?.clientWidth);
      }
      if (scrollContainerRef.current) {
        setContainerWidth(scrollContainerRef.current?.clientWidth);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [containerRef.current]);

  const handleMobileScroll = (event: UIEvent<HTMLDivElement>) => {
    const target = event.target as HTMLDivElement;
    setSliderMobileValue([target?.scrollLeft || 0]);
  };

  const HeadlineTag = getHeadlineTag(transformToHeadlineParams(data?.headline));
  const FontWeightClass = getFontWeightClass(
    transformToHeadlineParams(data?.headline),
  );
  const {
    fullHeight = false,
    marginTop = false,
    marginBottom = false,
  } = data || {};

  // Apply dynamic classes based on settings
  const containerClasses = `
    ${fullHeight ? 'fullscreen' : 'h-auto'}
    flex
    items-center
    justify-center
    ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
    ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
  `;
  const boxShadow = data?.boxShadow;
  const boxShadowBig = boxShadow ? 'box-shadow-big' : '';
  return (
    <section className={`section-category-slider w-full ${containerClasses}`}>
      <div className="container w-full">
        {/* Headline Section with Arrow */}
        <div className="w-full text-left mb-10 flex items-center">
          <HeadlineTag
            className={`${FontWeightClass}`}
            data-tina-field={tinaField(data, 'headline')}
          >
            {data?.headline?.text}
          </HeadlineTag>
          <div className="transform -rotate-90 ml-2 w-6 h-6">
            <SvgComponent />
          </div>
        </div>
        {/* Slider Grid Section */}
        <div className={`overflow-hidden hidden md:block`} ref={containerRef}>
          <div
            className="grid grid-flow-col overflow-hidden"
            ref={scrollContainerRef}
            style={{
              width: `${((data?.entries?.length || 1) + 1) * columnWidth}px`,
              paddingLeft: columnWidth / 2 + 'px',
              paddingRight: columnWidth / 2 + 'px',
              gridTemplateColumns: `repeat(${data?.entries?.length}, ${columnWidth}px)`,
            }}
          >
            {data?.entries?.map((entry, index) => (
              <div
                key={`category-slider#${index}`}
                className={`flex flex-row pb-4 gap-10  w-[${columnWidth}px]`}
              >
                <div className={`w-1/2 relative`}>
                  <img
                    src={entry?.image ?? ''}
                    alt={entry?.entryHeadline ?? ''}
                    data-tina-field={tinaField(entry, 'image')}
                    className={`${boxShadowBig} aspect-square`}
                  />
                </div>
                <div className="flex flex-col justify-between overflow-hidden w-1/2">
                  <h4
                    data-tina-field={tinaField(entry, 'entryHeadline')}
                    className="pb-5 light"
                  >
                    {entry?.entryHeadline}
                  </h4>
                  <p
                    data-tina-field={tinaField(entry, 'entryBody')}
                    className="leading-[32px] body-text light"
                  >
                    {entry?.entryBody?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    )) || ``}
                  </p>
                  {entry?.entryLinkTarget && (
                    <div className="flex items-center">
                      <SubmenuSingleIcon
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '5px',
                          color: entry.entryLinkColor ?? 'inherit',
                          marginTop: '20px',
                        }}
                      />
                      <SafeLink
                        href={entry.entryLinkTarget}
                        style={{
                          color: entry.entryLinkColor ?? '',
                          marginTop: '20px',
                        }}
                        tina={tinaField(entry, 'entryLinkTarget')}
                      >
                        {entry.entryLinkName}
                      </SafeLink>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Slider Control Destkop */}
        <div className="mt-10 mb-10 w-full hidden container md:contentGrid md:grid">
          <div className="col-start-3 col-span-2 -ml-24 ">
            <Slider
              value={sliderValue}
              onValueChange={(value) => {
                setSliderValue(value);
                scrollContainerRef.current?.parentElement?.scrollTo({
                  left: value[0], // Scroll with initial offset to align with the second column
                  behavior: 'instant',
                });
              }}
              min={0}
              max={containerWidth - 2 * columnWidth}
              className="mt-4 w-full"
            />
          </div>
        </div>

        {/* Mobile Version */}
        <div className={`contentGrid hidden md:hidden`}>
          <div
            className="col-span-4 block md:hidden mt-4 overflow-x-auto scrollbar-none"
            ref={containerMobileRef}
            onScroll={handleMobileScroll}
          >
            <div
              className="grid grid-flow-col md:hidden gap-4 mt-4 w-[200%]overflow-hidden"
              ref={scrollContainerMobileRef}
              style={{
                width: `${(data?.entries?.length || 1) * containerMobileWidth}px`,
                gridTemplateColumns: `repeat(${data?.entries?.length}, ${containerMobileWidth}px)`,
              }}
            >
              {data?.entries?.map((entry, index) => (
                <div
                  key={`category-slider-mobile#${index}`}
                  className="flex flex-col mb-4"
                >
                  <img
                    src={entry?.image ?? ''}
                    alt={entry?.entryHeadline ?? ''}
                    data-tina-field={tinaField(entry, 'image')}
                    className="object-cover w-full"
                  />
                  <h4
                    data-tina-field={tinaField(entry, 'entryHeadline')}
                    className="mt-2 text-lg font-bold"
                  >
                    {entry?.entryHeadline}
                  </h4>
                  <p
                    data-tina-field={tinaField(entry, 'entryBody')}
                    className="leading-[32px] light"
                  >
                    {entry?.entryBody?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    )) || ``}
                  </p>
                  {entry?.entryLinkTarget && (
                    <div className="flex items-center mt-2">
                      <SubmenuSingleIcon
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '5px',
                          color: entry.entryLinkColor ?? 'inherit',
                        }}
                      />
                      <SafeLink
                        href={entry.entryLinkTarget}
                        style={{
                          color: entry.entryLinkColor ?? '',
                        }}
                        tina={tinaField(entry, 'entryLinkTarget')}
                      >
                        {entry.entryLinkName}
                      </SafeLink>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* Slider Mobile */}
          <div className="col-start-2 col-span-3 mt-10 mb-10 w-full container block md:hidden relative">
            <Slider
              value={sliderMobileValue}
              onValueChange={(value) => {
                setSliderMobileValue(value);
                scrollContainerMobileRef.current?.parentElement?.scrollTo({
                  left: value[0], // Scroll with initial offset to align with the second column
                  behavior: 'instant',
                });
              }}
              min={0}
              max={
                (scrollContainerMobileRef.current?.clientWidth || 0) -
                containerMobileWidth
              }
              className=" mt-4 w-full"
            />
          </div>
        </div>
      </div>
    </section>
  );
}

CategorySlider.blockName = 'PageBlocksCategorySlider';
