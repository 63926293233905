import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksCheckList,
  SettingsPartsFragment,
} from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import ChecklistCheckIcon from '@libs/ui/utils/Templates/Shapes/checklist_check_icon';
import {
  getFontWeightClassFromData,
  getHeadlineTagFromData,
} from '@libs/ui/utils/Templates/style';
import { useEffect, useRef, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';

export default function CheckList({
                                    data,
                                    site,
                                  }: {
  data: PageBlocksCheckList;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const [shapesWidth, setShapesWidth] = useState(0);
  const shapesRef = useRef<HTMLDivElement>(null);

  if (data && data.entriesTexts) {
    const HeadlineTag = getHeadlineTagFromData(data?.headline);
    const fontWeightClass = getFontWeightClassFromData(data?.headline);

    // Calculate remaining width for the headline
    useEffect(() => {
      if (shapesRef.current) {
        setShapesWidth(shapesRef.current.offsetWidth);
      }
    }, [data]);

    const headlineWidth = `calc(100% - ${shapesWidth + 40}px)`;
    const { fullHeight = false, marginTop = false, marginBottom = false } = data || {};

    const containerClasses = `
      ${fullHeight ? 'fullscreen' : 'h-auto'}
      flex
      items-center
      justify-center
      ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
      ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
    `;

    return (
      <section className={`section-checklist pt-[50px] md:pt-[100px] p-[50px] md:p-[100px] container ${containerClasses}`}>
        {/* Headline and Shape Options Container */}
        <div className="grid contentGrid">
          <div className="w-full items-start col-span-3 xl:col-span-1 flex flex-col">
            {/* Headline Container */}
            <HeadlineTag
              className={`${fontWeightClass} break-words`}
              style={{ width: headlineWidth }}
              data-tina-field={tinaField(data, 'headline')}
            >
              {data?.headline?.text}
            </HeadlineTag>
          </div>
          <div className="w-full items-start col-span-1 xl:col-span-1 flex flex-col">
            {/* Shape Options Container */}
            <div
              className="ml-auto"
              ref={shapesRef}
              data-tina-field={tinaField(data, 'shapeOptions')}
            >
              <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
            </div>
          </div>
          <div className="w-full col-span-4 xl:col-span-2 flex flex-col mt-[40px] xl:mt-0">
            {/* Entries Container */}
            <div className="grid grid-cols-4 gap-x-0 gap-y-4">
              {data.entriesTexts.map((entry, index) => (
                <div
                  key={`cl-entry#${index}`}
                  className={`flex items-center pb-[20px] ${
                    index % 2 === 0
                      ? 'col-span-4' // Odd entries span the full width of the grid
                      : 'xl:col-start-3 xl:col-span-2 col-start-2 col-span-3' // Even entries start at column 2 and span 3 columns
                  }`}
                >
                  <div className="mr-2">
                    <ChecklistCheckIcon />
                  </div>
                  <span>{entry}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
CheckList.blockName = 'PageBlocksCheckList';
