import { PageBlocksHeroSection } from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import SvgSubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getAlignmentClassName,
  getFontWeightClass,
  getHeadlineTag,
  getVariantHeightClass,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../../utils/safe-link';

export default function HeroSection({ data }: { data: PageBlocksHeroSection }) {
  const HeadlineTag = getHeadlineTag(transformToHeadlineParams(data?.headline));
  const FontWeightClass = getFontWeightClass(
    transformToHeadlineParams(data?.headline),
  );
  const SublineTag = getHeadlineTag(transformToHeadlineParams(data?.subline));
  const SublineClass = getFontWeightClass(
    transformToHeadlineParams(data?.subline),
  );
  const VariantHeight = getVariantHeightClass(data?.variantHeight);

  const formattedMedia = data?.media
    ? data.media
        .replace(/ /g, '%20')
        .replace(/\(/g, '%28')
        .replace(/\)/g, '%29')
        .replace(/=/g, '%3D')
    : '';

  const isVideo = formattedMedia && formattedMedia.endsWith('.mp4');

  return (
    <section className="relative p-0 herosection">
      {/* Background Video/Image */}
      {isVideo ? (
        <video
          className="absolute inset-0 w-full h-full object-cover"
          autoPlay
          loop
          muted
          playsInline
          src={formattedMedia}
        />
      ) : (
        <div
          className="absolute inset-0 bg-cover bg-center"
          style={{backgroundImage: `url(${formattedMedia})`}}
        />
      )}

      {/* Container for content */}
      <div className="container relative z-10 pb-0">
        {data?.variant === 'Left' && data?.variantHeight === 'Small' && (
          <div
            className={`contentGrid grid items-center ${getAlignmentClassName(data?.variant)} ${VariantHeight}`}
          >
            <div
              className="col-span-8 md:col-span-2 flex flex-col justify-center"
              data-tina-field={tinaField(data)}
            >
              {/* Headline */}
              <HeadlineTag
                className={`${FontWeightClass} mb-[40px]`}
                data-tina-field={tinaField(data, 'headline')}
              >
                {data.headline?.text}
              </HeadlineTag>

              {/* Shape Component */}
              <div
                className="flex justify-end pb-[40px] md:pb-[80px]"
                data-tina-field={tinaField(data, 'shapeOptions')}
              >
                <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
              </div>

              {/* Subline */}
              <SublineTag
                className={`${SublineClass} mb-[40px] md:mb-[10px]`}
                data-tina-field={tinaField(data, 'subline')}
              >
                {data.subline?.text}
              </SublineTag>

              {/* Link Button */}

              {data.textLinkButton?.link && data.textLinkButton?.buttonText ? (
                <SafeLink
                  href={data.textLinkButton?.link ?? '#'}
                  tina={tinaField(data, 'textLinkButton')}
                  className="flex items-center"
                >
                  <SvgSubmenuSingleIcon className="mr-2" />
                  <span className="font-bold">
                    {data.textLinkButton?.buttonText}
                  </span>
                </SafeLink>
              ) : null}
            </div>
          </div>
        )}

        {data?.variant === 'Left' &&
          (data?.variantHeight === 'Screen Height' ||
            data?.variantHeight === 'Large') && (
            <div
              className={`flex flex-col justify-center items-center ${getAlignmentClassName(data?.variant)} ${VariantHeight}`}
              style={{ height: '100vh' }}
            >
              <div className="contentGrid grid">
                {/* Full-width Headline */}
                <div className="col-span-8 md:col-span-4">
                  <HeadlineTag
                    className={`${FontWeightClass} mb-[40px] md:mb-0`}
                    data-tina-field={tinaField(data, 'headline')}
                  >
                    {data.headline?.text}
                  </HeadlineTag>
                </div>

                <div
                  className="col-span-8 md:col-span-2 flex flex-col justify-center"
                  data-tina-field={tinaField(data)}
                >
                  {/* Shape Component */}
                  <div
                    className="flex justify-end pb-[40px] md:pb-[80px]"
                    data-tina-field={tinaField(data, 'shapeOptions')}
                  >
                    <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
                  </div>

                  {/* Subline */}
                  <SublineTag
                    className={`${SublineClass} mb-[40px]`}
                    data-tina-field={tinaField(data, 'subline')}
                  >
                    {data.subline?.text}
                  </SublineTag>

                  {/* Link Button */}
                  {data.textLinkButton?.link &&
                  data.textLinkButton?.buttonText ? (
                    <SafeLink
                      href={data.textLinkButton?.link ?? '#'}
                      tina={tinaField(data, 'textLinkButton')}
                      className="flex items-center"
                    >
                      <SvgSubmenuSingleIcon className="mr-2" />
                      <span className="font-bold">
                        {data.textLinkButton?.buttonText}
                      </span>
                    </SafeLink>
                  ) : null}
                </div>
              </div>
            </div>
          )}

        {data?.variant === 'Right' && (
          <div>
            <div className="hidden md:block ">
              <div
                className={`contentGrid grid-rows-4 gap-8 relative ${getAlignmentClassName(data?.variant)} ${VariantHeight}`}
              >
                {/* Shape Component */}
                <div
                  className="hero-right-shape flex justify-between row-span-2 md:col-span-2 col-span-4"
                  data-tina-field={tinaField(data, 'shapeOptions')}
                >
                  {/* Body Text */}
                  <SublineTag
                    className={`${SublineClass}`}
                    data-tina-field={tinaField(data, 'body')}
                  >
                    {data.body?.text}
                  </SublineTag>
                  <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
                </div>

                {/* Headline */}
                <div className="hero-right-headline flex flex-col justify-end row-span-2 col-start-1 col-span-2">
                  <HeadlineTag
                    className={`${FontWeightClass} mb-0`}
                    data-tina-field={tinaField(data, 'headline')}
                  >
                    {data.headline?.text}
                  </HeadlineTag>
                </div>

                {/* Subline */}
                <div className="hero-right-subline flex flex-col justify-end row-span-2 col-start-3 col-span-2">
                  <SublineTag
                    className={`${SublineClass} mb-0`}
                    data-tina-field={tinaField(data, 'subline')}
                  >
                    {data.subline?.text}
                  </SublineTag>
                </div>
              </div>
            </div>
            <div
              className={`md:hidden grid-cols-2 grid-rows-2 gap-4 relative ${getAlignmentClassName(data?.variant)} ${VariantHeight}`}
            >
              {/*Mobile Layout*/}
              <div className="flex flex-col w-full items-start justify-center h-full">
                <HeadlineTag
                  className={`${FontWeightClass}  hero-headline-mobile`}
                  data-tina-field={tinaField(data, 'headline')}
                >
                  {data.headline?.text}
                </HeadlineTag>

                <div className="flex justify-end w-full hero-shapes-mobile">
                  <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
                </div>

                <SublineTag
                  className={`${SublineClass} mb-0`}
                  data-tina-field={tinaField(data, 'subline')}
                >
                  {data.subline?.text}
                </SublineTag>

                {data.textLinkButton?.link &&
                data.textLinkButton?.buttonText ? (
                  <SafeLink
                    href={data.textLinkButton?.link ?? '#'}
                    tina={tinaField(data, 'textLinkButton')}
                    className="flex items-center justify-start hero-button-mobile self-start"
                  >
                    <SvgSubmenuSingleIcon className="mr-2" />
                    <span className="font-bold">
                      {data.textLinkButton?.buttonText}
                    </span>
                  </SafeLink>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

HeroSection.blockName = 'PageBlocksHeroSection';
