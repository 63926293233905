import { i18n } from '@apps/i18n-config';
import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksNewsCardsBlogPosts,
  SettingsPartsFragment,
} from '@generated/types';
import { ArrowRight } from '@libs/ui/utils/Templates/Shapes';
import { Fragment, useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { Sides } from '../../../tina/blocks/utils';
import { TrimmedArticle } from '../../Page';
import { getLangAndCleanSlug } from '../../utils';
import { SafeLink } from '../../utils/safe-link';
import {
  getFontWeightClass,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '../../utils/Templates/style';

const filterArticles = (
  category: string | null,
  year: number,
  articles: TrimmedArticle[],
) => {
  const start = new Date(year, 0, 1).toISOString();
  const end = new Date(year, 11, 31).toISOString();
  return articles?.filter(
    (a) =>
      a.categories?.includes(category) &&
      new Date(a.createdAt!) >= new Date(start) &&
      new Date(a.createdAt!) <= new Date(end),
  );
};

export default function NewsCardsBlogPosts({
  data,
  global,
  settings,
  site,
  articles,
}: {
  data: PageBlocksNewsCardsBlogPosts;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
  articles?: TrimmedArticle[] | null;
}) {
  const [year, setYear] = useState<number>(
    data?.defaultYear ? Number(data?.defaultYear) : new Date().getFullYear(),
  );
  const [category, setCategory] = useState<string | null>(
    data?.defaultCategory || data?.categories?.[0] || null,
  );
  const defaultArticles = articles && filterArticles(category, year, articles);
  const [articleData, setArticleData] = useState<
    TrimmedArticle[] | undefined | null
  >(defaultArticles);

  useEffect(() => {
    (async () => {
      if (data.categories) {
        try {
          const res = articles && filterArticles(category, year, articles);
          setArticleData(res);
        } catch (e) {
          console.log(e);
        }
      } else {
        setArticleData(null);
      }
    })();
  }, [data.categories, year, category, articles]);

  // get a list of 5 last years, starting from the current year
  const years = Array.from(
    { length: 5 },
    (_, i) => new Date().getFullYear() - i,
  );
  return (
    <div className="container section-newscards-blog">
      <div className="grid grid-cols-2 md:grid-cols-4  gap-10 mb-8">
        <div
          className={`col-span-2 ${data.variant === Sides.Right ? 'hidden' : ''}`}
        />
        <div className="col-span-2 flex content-between justify-between pl-8">
          <span>Years</span>
          {years.map((y) => (
            <span
              key={y}
              data-selected={y === year}
              className="data-[selected=true]:underline data-[selected=true]:text-primary underline-offset-8 cursor-pointer text-primary/90 hover:text-primary"
              onClick={() => setYear(y)}
            >
              .{y}
            </span>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-2 md:grid-cols-4  gap-10">
        <FiltersColumn
          data={data}
          global={global}
          settings={settings}
          visible={data?.variant === Sides.Left}
          {...{ category, setCategory }}
        />

        <div className="col-span-2 md:col-span-3 grid grid-cols-1 md:grid-cols-3 gap-10">
          {(articleData || defaultArticles)?.map((article) => {
            // get date in the 23/11/01 format
            const date =
              article?.createdAt &&
              new Date(article?.createdAt).toLocaleDateString('en-GB', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              });
            const params = getLangAndCleanSlug(
              article.relativePath
                ?.replace('.mdx', '')
                .replace('.md', '')
                ?.split('/') || [],
              i18n?.locales || [],
            );
            const href = `${params?.lang ? `/${params.lang}` : ''}/articles/${params?.slug?.join('/')}`;
            return (
              <div key={article?.id} className="flex flex-col">
                <SafeLink href={href} className="contents">
                  <img
                    src={article?.image!}
                    alt={article?.imageAlt || article?.title || ''}
                    className="w-full h-auto object-cover aspect-square cursor-pointer"
                    data-tina-field={tinaField(article, 'title')}
                  />
                </SafeLink>
                <div className="flex flex-col w-full h-full md:min-h-60 justify-between">
                  <div className="flex flex-col flex-grow-0">
                    <SafeLink href={href} className="mt-6">
                      <span className="heading-4 font-extralight">{date}</span>
                    </SafeLink>
                    <SafeLink href={href} className="mt-4 font-light">
                      <span className="font-light">{article?.title}</span>
                    </SafeLink>
                  </div>
                  <SafeLink href={href} className="flex mt-8 mb-4 md:mb-0">
                    <span className="-ml-1.5">
                      <ArrowRight width="w-[24px]" />
                    </span>
                    Learn More
                  </SafeLink>
                </div>
              </div>
            );
          })}
        </div>
        {data?.variant === Sides.Right && (
          <FiltersColumn
            data={data}
            global={global}
            settings={settings}
            right
            {...{ category, setCategory }}
          />
        )}
      </div>
    </div>
  );
}

const FiltersColumn = ({
  data,
  global,
  settings,
  category,
  setCategory,
  visible = true,
  right = false,
}: {
  data: PageBlocksNewsCardsBlogPosts;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  category: string | null;
  setCategory: (category: string) => void;
  visible?: boolean;
  right?: boolean;
}) => {
  const { headline, categories, variant } = data;

  const headlineParams = transformToHeadlineParams(data?.headline);
  const HeadlineTag = getHeadlineTagFromData(headlineParams);
  const fontWeightClass = getFontWeightClass(headlineParams);

  return (
    <div
      className={`flex flex-col col-span-2 md:col-span-1  space-y-4 ${visible ? '' : 'md:hidden'} ${right ? 'hidden' : 'md:flex'}`}
    >
      {/* Headline */}
      {data?.headline?.text && (
        <HeadlineTag
          className={fontWeightClass}
          data-tina-field={tinaField(data, 'headline')}
        >
          {data.headline.text}
        </HeadlineTag>
      )}
      {/* Dots */}
      <span className="mr-2">.</span>
      <span className="mr-2">.</span>
      {/* Categories */}
      {data?.categories?.map((c, index) => (
        <Fragment key={index}>
          <span className="mr-2">.</span>
          <div
            key={index}
            data-tina-field={tinaField(data, 'categories', index)}
            data-selected={category === c}
            className="capitalize data-[selected=true]:underline data-[selected=true]:text-primary underline-offset-8 cursor-pointer text-primary/90 hover:text-primary"
            onClick={() => c && setCategory(c)}
          >
            .{` `}
            <span>{c}</span>
          </div>
        </Fragment>
      ))}
    </div>
  );
};
NewsCardsBlogPosts.blockName = 'PageBlocksNewsCardsBlogPosts';
