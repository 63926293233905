import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksAnimationText,
  SettingsPartsFragment,
} from '@generated/types';
import { ArrowRight } from '@libs/ui/utils/Templates/Shapes';
import { Fragment, ReactNode } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { Shape } from '../../../tina/blocks/utils';
import ShapesComponent, { SingleShapeComponent } from '../../shared/shapes';
import { SafeLink } from '../../utils/safe-link';
import {
  getFontWeightClass,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '../../utils/Templates/style';

export default function AnimationText(props: {
  data: PageBlocksAnimationText;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  return (
    <AnimationTextReusable {...props}>
      <img src={props?.data.media!} />
    </AnimationTextReusable>
  );
}

export type RecursiveOmit<T, K extends keyof any> = T extends object
  ? T extends Array<infer U> // If the field is an array, recursively apply Omit to array elements
    ? Array<RecursiveOmit<U, K>>
    : {
        [P in keyof T as P extends K ? never : P]: RecursiveOmit<T[P], K>;
      }
  : T;

export function AnimationTextReusable({
  data,
  global,
  site,
  children,
}: {
  data: RecursiveOmit<Omit<PageBlocksAnimationText, 'media'>, '__typename'>;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
  children?: ReactNode;
}) {
  const headlineParams = transformToHeadlineParams(data?.headline);
  const HeadlineTag = getHeadlineTagFromData(headlineParams);
  const fontWeightClass = getFontWeightClass(headlineParams);

  return (
    <section
      data-tina-field={tinaField(data)}
      className="section-animation-text contentGrid container grid grid-cols-4 mb-8"
    >
      <div className="col-span-4 md:col-span-2">
        <div
          className={`min-w-[230px] flex flex-col text-start justify-start relative overflow-hidden`}
        >
          {children}
        </div>
      </div>
      <div className={`col-span-4 md:col-span-2 flex items-center static`}>
        <div className={`grid grid-cols-4 space-y-4 gap-x-10 w-full`}>
          {/* Headline */}
          <div className="col-span-3 md:col-span-4">
            <div className="w-0 relative">
              <span className="hidden md:block -z-50 absolute -left-4 xl:-left-custom-xl-gap hd:-left-custom-hd-gap 2k:-left-custom-2k-gap -ml-[48px] md:-ml-[45px] lg:-ml-[75px] xl:-ml-[75px]">
                {data.shapeOptions && (
                  <ShapesComponent shapeOptions={data.shapeOptions} />
                )}
              </span>
            </div>
            {data?.headline?.text && (
              <HeadlineTag
                className={fontWeightClass}
                data-tina-field={tinaField(data, 'headline')}
              >
                {data.headline.text}
              </HeadlineTag>
            )}
          </div>
          <div className="col-span-1 p-0 !mt-0 md:hidden">
            {data.shapeOptions && (
              <ShapesComponent shapeOptions={data.shapeOptions} />
            )}
          </div>
          <div className="flex flex-col space-y-4 col-span-2">
            {/* Dots */}
            <span className="mr-2">.</span>
            {/* Categories */}
            {data?.entries?.map((entry, index) => (
              <Fragment key={index}>
                <span className="mr-2">.</span>
                <SafeLink href={entry?.fieldLink}>
                  <div
                    key={index}
                    data-tina-field={tinaField(entry)}
                    className="capitalize  whitespace-nowrap data-[selected=true]:underline data-[selected=true]:text-primary underline-offset-8 cursor-pointer text-primary/90 hover:text-primary flex "
                  >
                    <div className="relative w-0">
                      <span className="hidden -z-50 md:block absolute -left-4 xl:-left-custom-xl-gap hd:-left-custom-hd-gap 2k:-left-custom-2k-gap bottom-1 -ml-[16px] md:-ml-[15px] lg:-ml-[25px] xl:-ml-[25px]">
                        <SingleShapeComponent
                          shape={(entry?.shape as Shape) || Shape.Empty}
                        />
                      </span>
                    </div>
                    <div className="justify-between flex flex-1">
                      <div>
                        .{` `}
                        <span>{entry?.fieldText}</span>
                      </div>
                      <span className="-mt-[4px] w-[16px]">
                        <ArrowRight width="w-[24px]" />
                      </span>
                    </div>
                  </div>
                </SafeLink>
              </Fragment>
            ))}
          </div>
          <div className="flex md:hidden flex-col space-y-4 col-span-2">
            {/* Dots */}
            <span className="mr-2">&nbsp;&nbsp;</span>
            {/* Categories */}
            {data?.entries?.map((entry, index) => (
              <Fragment key={index}>
                <span className="mr-2">&nbsp;&nbsp;</span>
                <SafeLink href={entry?.fieldLink}>
                  <div
                    key={index}
                    data-tina-field={tinaField(entry)}
                    className="capitalize relative whitespace-nowrap data-[selected=true]:underline data-[selected=true]:text-primary underline-offset-8 cursor-pointer text-primary/90 hover:text-primary flex justify-between"
                  >
                    <span className="block"></span>
                    <SingleShapeComponent
                      shape={(entry?.shape as Shape) || Shape.Empty}
                    />
                  </div>
                </SafeLink>
              </Fragment>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

AnimationText.blockName = 'PageBlocksAnimationText';
