import { i18n } from '@apps/i18n-config';
import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksNewsListBlogPosts,
  SettingsPartsFragment,
} from '@generated/types';
import { ArrowRight } from '@libs/ui/utils/Templates/Shapes';
import { Fragment, useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import { Shape } from '../../../tina/blocks/utils';
import { TrimmedArticle } from '../../Page';
import { SingleShapeComponent } from '../../shared/shapes';
import { getLangAndCleanSlug } from '../../utils';
import { SafeLink } from '../../utils/safe-link';
import {
  getFontWeightClass,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '../../utils/Templates/style';

const filterArticles = (
  category: string | null,

  articles: TrimmedArticle[],
  postsToShow?: number | null,
) => {
  return articles
    ?.filter((a) => a.categories?.includes(category))
    .sort((a, b) => {
      return (
        new Date(b.createdAt!).getTime() - new Date(a.createdAt!).getTime()
      );
    })
    .filter((_, index) => !postsToShow || index < Number(postsToShow));
};

export default function NewsListBlogPosts({
  data,
  global,
  settings,
  site,
  articles,
}: {
  data: PageBlocksNewsListBlogPosts;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
  articles?: TrimmedArticle[] | null;
}) {
  const [category, setCategory] = useState<string | null>(null);
  const defaultArticles = articles && filterArticles(category, articles);
  const [articleData, setArticleData] = useState<
    TrimmedArticle[] | undefined | null
  >(defaultArticles);

  useEffect(() => {
    (async () => {
      if (data.categories) {
        // first and last day of the year
        try {
          const res =
            articles && filterArticles(category, articles, data?.postsToShow);
          setArticleData(res);
        } catch (e) {
          console.log(e);
        }
      } else {
        setArticleData(null);
      }
    })();
  }, [
    data.categories,
    category,
    data.postsToShow,
    data.showSpecificNews,
    data.specificNewsEntries,
  ]);

  return (
    <div className="container section-newslist-blog">
      <div className="grid grid-cols-2 md:grid-cols-4 gap-10">
        <div className="col-span-2 md:col-span-3 flex flex-col">
          {(data.showSpecificNews
            ? data?.specificNewsEntries
                ?.map((v) => v?.article)
                ?.map((v) => ({ ...v, relativePath: v?._sys?.relativePath }))
            : articleData
          )?.map((article, index) => {
            // get date in the 23/11/01 format
            const date =
              article?.createdAt &&
              new Date(article?.createdAt).toLocaleDateString('en-GB', {
                year: '2-digit',
                month: '2-digit',
                day: '2-digit',
              });
            const params = getLangAndCleanSlug(
              article?.relativePath
                ?.replace('.mdx', '')
                .replace('.md', '')
                ?.split('/') || [],
              i18n?.locales || [],
            );
            const shape =
              (data?.shapesOverrides?.[index] as Shape) || Shape.Empty;
            return (
              <>
                <div key={article?.id} className="grid grid-cols-3 gap-10">
                  <div className="font-light mt-6 col-span-1">
                    {[...(date?.split('/') || [])].reverse()?.join(' ')}
                  </div>
                  <div className="font-light mt-6 col-span-1 capitalize flex justify-between">
                    <span>{article?.categories?.join(', ')}</span>
                    <span>
                      <SingleShapeComponent shape={shape} />
                    </span>
                  </div>
                  <SafeLink
                    href={`${params?.lang ? `/${params.lang}` : ''}/articles/${params?.slug?.join('/')}`}
                    className="font-light mt-6 col-span-1 flex justify-between cursor-pointer"
                  >
                    Read More
                    <span className="ml-1.5 -mt-0.5">
                      <ArrowRight width="w-[24px]" />
                    </span>
                  </SafeLink>
                </div>
                <div className="w-full text-ellipsis overflow-hidden static whitespace-nowrap mt-8 pb-4 border-b border-primary/50">
                  {article?.title}
                </div>
              </>
            );
          })}
        </div>

        <FiltersColumn
          data={data}
          global={global}
          settings={settings}
          {...{ category, setCategory }}
        />
      </div>
    </div>
  );
}

const FiltersColumn = ({
  data,
  global,
  settings,
  category,
  setCategory,
}: {
  data: PageBlocksNewsListBlogPosts;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  category: string | null;
  setCategory: (category: string) => void;
}) => {
  const { headline, categories } = data;

  const headlineParams = transformToHeadlineParams(data?.headline);
  const HeadlineTag = getHeadlineTagFromData(headlineParams);
  const fontWeightClass = getFontWeightClass(headlineParams);

  return (
    <div className="flex flex-col col-span-2 md:col-span-1 space-y-4">
      {/* Headline */}
      {data?.headline?.text && (
        <HeadlineTag
          className={fontWeightClass}
          data-tina-field={tinaField(data, 'headline')}
        >
          {data.headline.text}
        </HeadlineTag>
      )}
      {data.showFilter && !data.showSpecificNews && (
        <>
          {/* Categories */}
          <span className="h-8 w-full"></span>
          {data?.categories?.map((c, index) => (
            <Fragment key={index}>
              <div
                key={index}
                data-tina-field={tinaField(data, 'categories', index)}
                data-selected={category === c}
                className="capitalize data-[selected=true]:underline data-[selected=true]:text-primary underline-offset-8 cursor-pointer text-primary/90 hover:text-primary"
                onClick={() => c && setCategory(c)}
              >
                .{` `}
                <span>{c}</span>
              </div>
            </Fragment>
          ))}
        </>
      )}
    </div>
  );
};
NewsListBlogPosts.blockName = 'PageBlocksNewsListBlogPosts';
