import { Site } from '@apps/site';
import { PageBlocksAccordion } from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import {
  getFontWeightClassFromData,
  getHeadlineTagFromData,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';

export default function Accordion({
  data,
  site
}: {
  data: PageBlocksAccordion;
  site: Site;
}) {
  const HeadlineTag = getHeadlineTagFromData(data?.headline);
  const fontWeightClass = getFontWeightClassFromData(data?.headline);

  return (
    <section
      className="section-accordion w-full border rounded p-4"
      data-tina-field={tinaField(data)}
    >
      {/* Headline */}
      {data?.headline?.text && (
        <HeadlineTag
          className={fontWeightClass}
          data-tina-field={tinaField(data, 'headline')}
        >
          {data.headline.text}
        </HeadlineTag>
      )}

      {/* Entries */}
      {data?.entries && data.entries.length > 0 && (
        <div className="mt-4">
          {data.entries.map((entry, index) => (
            <div key={`accordion-entry-${index}`} className="mb-4">
              {/* Entry Headline */}
              {entry?.entryHeadline && (
                <p
                  className="font-semibold"
                  data-tina-field={tinaField(entry, 'entryHeadline')}
                >
                  {entry.entryHeadline}
                </p>
              )}

              {/* Entry Body */}
              {entry?.entryBody && (
                <p
                  className="mt-2"
                  data-tina-field={tinaField(entry, 'entryBody')}
                >
                  {entry.entryBody}
                </p>
              )}

              {/* ShapeSwitcher Component */}
              {entry?.shapeOptions && (
                <div data-tina-field={tinaField(entry, 'shapeOptions')}>
                  <ShapesComponent shapeOptions={entry.shapeOptions} />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </section>
  );
}

Accordion.blockName = 'PageBlocksAccordion';
