import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksHorizontalCards,
  SettingsPartsFragment,
} from '@generated/types';

export default function HorizontalCards({
  data,
  global,
  settings,
  site
}: {
  data: PageBlocksHorizontalCards;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  return (
    <div className="w-full h-20 border rounded p-4 flex items-center justify-center">
      HorizontalCards
    </div>
  );
}
HorizontalCards.blockName = 'PageBlocksHorizontalCards';
