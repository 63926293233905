import { Site } from '@apps/site';
import { PageBlocksMediumTeaserText } from '@generated/types';
import { FontSize, FontWeight } from '@libs/tina/blocks/utils';
import {
  getFontSizeClass,
  getFontWeightClass,
  getHeadlineTag,
} from '@libs/ui/utils/Templates/style';
import { tinaField } from 'tinacms/dist/react';

export default function MediumTeaserText({
  data,
  site,
}: {
  data: PageBlocksMediumTeaserText;
  site: Site;
}) {
  const Tag = getHeadlineTag({ fontSize: data?.body?.fontSize as FontSize });
  const fontSizeClass = getFontSizeClass({
    fontSize: data?.body?.fontSize as FontSize,
  });
  const fontWeightClass = getFontWeightClass({
    fontWeight: data?.body?.fontWeight as FontWeight,
  });
  const isTextRight = data.variant === 'Text Right';

  const { fullHeight = false, marginTop = false, marginBottom = false } = data || {};


  // Apply dynamic classes based on settings
  const containerClasses = `
    ${fullHeight ? 'fullscreen' : 'h-auto'}
    flex
    items-center
    justify-center
    ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
    ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
  `;

  return (
    <section className={`section-medium-teaser container contentGrid grid ${containerClasses}`}>
        <div
          data-tina-field={tinaField(data, 'body')}
          className={`
          col-span-4 md:col-span-2 ${isTextRight ? 'md:col-start-3 col-start-3' : 'md:col-start-1 col-start-1'}
          w-full rounded ${fontWeightClass}`}
        >
          <Tag
            className={`${fontSizeClass}`}
            data-tina-field={tinaField(data.body, 'text')}
          >
            {data?.body?.text}
          </Tag>
        </div>
    </section>
  );
}

MediumTeaserText.blockName = 'PageBlocksMediumTeaserText';
