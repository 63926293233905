import { Site } from '@apps/site';
import { PageBlocksInfoText } from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import SvgSubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import {
  getFontSizeClass,
  getFontWeightClass,
  getFontWeightClassFromData,
  getHeadlineTagFromData,
  getVariantAlignmentClass,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import React from 'react';
import { tinaField } from 'tinacms/dist/react';
import { SafeLink } from '../../utils/safe-link';

export default function InfoText({
  data,
  site,
}: {
  data: PageBlocksInfoText;
  site: Site;
}) {
  const HeadlineTag = getHeadlineTagFromData(data?.headline);
  const fontWeightClass = getFontWeightClassFromData(data?.headline);
  const headlineParams = transformToHeadlineParams(data?.headline);
  const headlineFontSizeClass = getFontSizeClass(headlineParams);
  const bodyParams = transformToHeadlineParams(data?.body);
  const bodyFontWeightClass = getFontWeightClass(bodyParams);
  const bodyFontSizeClass = getFontSizeClass(bodyParams);
  const BodyTag = getHeadlineTagFromData(data?.body);
  const variantClass = getVariantAlignmentClass(data.variant);
  const isHeadlineRight = data.variant === 'Headline Right - Text Left';
  // Extract the settings from data
  const {
    fullHeight = false,
    marginTop = false,
    marginBottom = false,
  } = data || {};

  // Apply dynamic classes based on settings
  const containerClasses = `
    ${fullHeight ? 'fullscreen' : 'h-auto'}
    flex
    items-center
    justify-center
    ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
    ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
  `;

  return (
    <section
      className={`section-info-text md:pt-[100px] pt-[50px] md:pb-[100px] pb-[50px] container w-full flex items-center justify-center !mb-0 ${variantClass} ${containerClasses}`}
    >
      <div className="contentGrid grid">
        {isHeadlineRight ? (
          <>
            <div
              className={`col-span-4 xl:col-span-2 flex flex-col mb-4 xl:mb-0`}
            >
              <div className="flex flex-col items-start mb-4 xl:gap-[30px] gap-[40px]">
                {/* Mobile Headline Above Body Text */}
                {data?.headline?.text && (
                  <div
                    className={`xl:hidden flex justify-between items-start  w-full mb-4`}
                  >
                    <HeadlineTag
                      className={`${fontWeightClass} ${headlineFontSizeClass} self-start`}
                      data-tina-field={tinaField(data, 'headline')}
                    >
                      {data?.headline?.text?.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      )) || ``}
                    </HeadlineTag>

                    {data?.shapeOptions && (
                      <div className="ml-10 items-end">
                        <ShapesComponent
                          shapeOptions={data?.shapeOptions || {}}
                        />
                      </div>
                    )}
                  </div>
                )}

                {/* Body Text */}
                {data?.body?.text && (
                  <BodyTag
                    className={`${bodyFontWeightClass} ${bodyFontSizeClass} xl:mt-0`}
                    data-tina-field={tinaField(data, 'body')}
                  >
                    {data?.body?.text?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    )) || ``}
                  </BodyTag>
                )}
                {/* Link Button */}
                {data?.linkButton?.link && data?.linkButton?.buttonText && (
                  <SafeLink
                    href={data.linkButton.link}
                    className="font-medium flex items-center"
                  >
                    <SvgSubmenuSingleIcon className="mr-2" />
                    {data.linkButton.buttonText}
                  </SafeLink>
                )}
              </div>
            </div>

            {/* Right Side - Headline (Desktop Only) */}
            <div
              className={`col-span-4 xl:col-span-2 flex flex-col items-start mb-4 xl:mb-0`}
            >
              {/* Headline (Desktop) */}
              {data?.headline?.text && (
                <HeadlineTag
                  className={`${fontWeightClass} ${headlineFontSizeClass} self-end hidden xl:block`} // Hidden on mobile
                  data-tina-field={tinaField(data, 'headline')}
                >
                  {data.headline.text}
                </HeadlineTag>
              )}
            </div>

            {!isHeadlineRight && data?.shapeOptions && (
              <div className="ml-10" style={{ marginLeft: '40px' }}>
                <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
              </div>
            )}
          </>
        ) : (
          <>
            <div className="col-span-4 xl:col-span-2 grid grid-cols-4 gap-4 flex-col mb-4 xl:mb-0">
              <div className="col-span-3 flex items-start mb-4">
                {data?.headline?.text && (
                  <div className={`flex flex-col items-start mb-4`}>
                    <HeadlineTag
                      className={`${fontWeightClass} ${headlineFontSizeClass} self-start`}
                      data-tina-field={tinaField(data, 'headline')}
                    >
                      {data?.headline?.text?.split('\n').map((line, index) => (
                        <React.Fragment key={index}>
                          {line}
                          <br />
                        </React.Fragment>
                      )) || ``}
                    </HeadlineTag>
                  </div>
                )}
              </div>

              {!isHeadlineRight && data?.shapeOptions && (
                <div className="col-span-1 ml-10 flex flex-col mb-4 xl:mb-0 items-end">
                  <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
                </div>
              )}
            </div>

            <div className="col-span-4 xl:col-span-2 flex flex-col items-start mb-4 xl:mb-0">
              <div className="flex w-full flex-col items-start mb-4 xl:gap-[30px] gap-[40px]">
                {/* Body Text */}
                {data?.body?.text && (
                  <BodyTag
                    className={`${bodyFontWeightClass} ${bodyFontSizeClass} xl:mt-0`}
                    data-tina-field={tinaField(data, 'body')}
                  >
                    {data?.body?.text?.split('\n').map((line, index) => (
                      <React.Fragment key={index}>
                        {line}
                        <br />
                      </React.Fragment>
                    )) || ``}
                  </BodyTag>
                )}
                {/* Link Button */}
                {data?.linkButton?.link && data?.linkButton?.buttonText && (
                  <SafeLink
                    href={data.linkButton.link}
                    className="font-medium flex items-center"
                  >
                    <SvgSubmenuSingleIcon className="mr-2" />
                    {data.linkButton.buttonText}
                  </SafeLink>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
}

InfoText.blockName = 'PageBlocksInfoText';
