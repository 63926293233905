import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksFourColSteps,
  SettingsPartsFragment,
} from '@generated/types';
import lottie from 'lottie-web';
import { useEffect, useRef, useState } from 'react';

export default function FourColSteps({
  data,
  global,
  settings,
  site,
}: {
  data: PageBlocksFourColSteps;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [animationInstance, setAnimationInstance] = useState<any>(null);
  const [animationData, setAnimationData] = useState<any>(null);

  // Extract the settings from data
  const {
    fullHeight = false,
    marginTop = false,
    marginBottom = false,
    useAnimation = false,
  } = data || {};

  // Apply dynamic classes based on settings
  const containerClasses = `
    lottieOuter
    fullwidth-container
    ${fullHeight ? 'fullscreen' : 'h-auto'}
    flex
    items-center
    justify-center
    ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
    ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
  `;

  useEffect(() => {
    if (useAnimation) {
      const loadAnimationData = async () => {
        let desktopData, mobileData;

        // Dynamically load animation data based on 'site'
        if (site === 'main-site') {
          desktopData = await import('./4colsteps_1920.json');
          mobileData = await import('./4colsteps_mobile.json');
        } else if (site === 'cosmetics') {
          desktopData = await import('./cosmetics_4colsteps_1920.json');
          mobileData = await import('./cosmetics_4colsteps_mobile.json');
        }

        // Choose appropriate data based on window width
        const selectedData =
          window.innerWidth >= 992 ? desktopData?.default : mobileData?.default;
        setAnimationData(selectedData);
      };

      loadAnimationData();
    }
  }, [site, useAnimation]);

  useEffect(() => {
    if (useAnimation && animationData && containerRef.current) {
      const instance = lottie.loadAnimation({
        container: containerRef.current,
        animationData: animationData,
        renderer: 'svg',
        loop: true,
        autoplay: false, // Start paused
      });
      setAnimationInstance(instance);

      return () => {
        if (instance) {
          instance.destroy(); // Clean up the animation instance
        }
      };
    }
  }, [animationData, useAnimation]);

  useEffect(() => {
    if (useAnimation) {
      const handleIntersection = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            animationInstance?.play();
          }
        });
      };

      const observer = new IntersectionObserver(handleIntersection, {
        threshold: 0.1, // Adjust this value to change when the animation starts
      });

      if (containerRef.current) {
        observer.observe(containerRef.current);
      }

      return () => {
        if (containerRef.current) {
          observer.unobserve(containerRef.current);
        }
      };
    }
  }, [animationInstance, useAnimation]);

  return (
    <section className={`section-fourcol-steps ${containerClasses}`}>
      {useAnimation ? (
        <div className="relative flex items-center justify-center">
          <div ref={containerRef} />
          {/* Render the container if useAnimation is true */}
        </div>
      ) : (
        <div className="container">
          <div className="grid contentGrid">
            <div className="col-span-4 md:col-span-2 xl:col-span-1 mb-4 xl:mb-0">
              <div className="heading-display">{data?.colOne?.displayText}</div>
              <h4>{data?.colOne?.colHeadline}</h4>
              <p>{data?.colOne?.colBody}</p>
            </div>
            <div className="col-span-4 md:col-span-2 xl:col-span-1 mb-4  xl:mb-0">
              <div className="heading-display">{data?.colTwo?.displayText}</div>
              <h4>{data?.colTwo?.colHeadline}</h4>
              <p>{data?.colTwo?.colBody}</p>
            </div>
            <div className="col-span-4 md:col-span-2 xl:col-span-1  mb-4 md:mb-0">
              <div className="heading-display">
                {data?.colThree?.displayText}
              </div>
              <h4>{data?.colThree?.colHeadline}</h4>
              <p>{data?.colThree?.colBody}</p>
            </div>
            <div className="col-span-4 md:col-span-2 xl:col-span-1 mb-0">
              <div className="heading-display">
                {data?.colFour?.displayText}
              </div>
              <h4>{data?.colFour?.colHeadline}</h4>
              <p>{data?.colFour?.colBody}</p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}

FourColSteps.blockName = 'PageBlocksFourColSteps';
