import Image, { ImageProps } from 'next/image';
import { MutableRefObject } from 'react';

export function getProps(url: string) {
  const [src, p] = url.split('?');
  const params = new URLSearchParams(p);
  const map = {
    width: params.get('width') ? Number(params.get('width')) : undefined,
    height: params.get('height') ? Number(params.get('height')) : undefined,
    fill: params.get('width') || params.get('height') ? false : true,
    src,
  };
  return map;
}

export function getVideoProps(url: string) {
  const [src, p] = url.split('?');
  const params = new URLSearchParams(p);
  const map = {
    width: params.get('width') ? Number(params.get('width')) : undefined,
    height: params.get('height') ? Number(params.get('height')) : undefined,
  };
  return map;
}

export const getVideoSrc = (url: string) => url.split('?')?.[0];

export function ImageOrVideo({
  forwardRef: outerRef,
  hideControls,
  speed,
  className = undefined,
  ...props
}: { hideControls?: boolean } & { speed?: number | null } & {
  forwardRef?: MutableRefObject<Element | null | undefined>;
} & (ImageProps | (Omit<ImageProps, 'src'> & { src: undefined | null }))) {
  return (
    (props.src && (
      <>
        {String(props.src).match(/(.*\.mp4$)|(.mp4\\?)/i) ? (
          <video
            ref={(el) => {
              if (el && speed) {
                el.defaultPlaybackRate = speed;
                el.playbackRate = speed;
              }
              if (outerRef) {
                outerRef.current = el;
              }
            }}
            autoPlay
            loop
            muted
            playsInline
            preload="metadata"
            {...{
              ...('width' in getVideoProps(String(props.src))
                ? getVideoProps(String(props.src))
                : {}),
              ...(String(props.src).includes(
                'https://assets.optindustries.com/optimized/',
              )
                ? {
                    poster: `/_next/image?url=${getVideoSrc(String(props.src))
                      .replace('/MP4/', '/Thumbnails/')
                      .replace('.mp4', '.0000000.jpg')}&w=1080&q=90`,
                  }
                : {}),
            }}
            key={String(props.src)}
            className={className}
          >
            <source
              key={String(props.src)}
              src={getVideoSrc(String(props.src)) + '#t=0.1'}
              type="video/mp4"
            />

            {String(props.src).includes(
              'https://assets.optindustries.com/optimized/',
            ) && (
              <Image
                {...{
                  ...props,
                  ...('width' in getVideoProps(String(props.src))
                    ? getVideoProps(String(props.src))
                    : {}),
                  fill:
                    'width' in getVideoProps(String(props.src)) ? false : true,
                }}
                key={String(props.src) + '_image'}
                src={getVideoSrc(String(props.src))
                  .replace('/MP4/', '/Thumbnails/')
                  .replace('.mp4', '.0000000.jpg')}
                title="Your browser does not support the <video> tag"
              />
            )}
          </video>
        ) : (
          <Image
            className={className}
            {...{
              ...props,
              ...('width' in getProps(String(props.src))
                ? getProps(String(props.src))
                : {}),
            }}
            key={String(props.src)}
          />
        )}
      </>
    )) || <></>
  );
}

export type ImageSet = {
  desktopImage?: string | null;
  tabletImage?: string | null;
  mobileImage?: string | null;
};

export function ImageOrVideoSet({
  hideControls,
  imageSet,
  ...props
}: { imageSet: ImageSet | null | undefined } & {
  hideControls?: boolean;
} & { speed?: number | null } & {
  forwardRef?: MutableRefObject<Element | null | undefined>;
} & (ImageProps | (Omit<ImageProps, 'src'> & { src: undefined | null }))) {
  return (
    <>
      {!imageSet
        ? props?.src && <ImageOrVideo {...props} hideControls={hideControls} />
        : props?.src && (
            <>
              <ImageOrVideo
                forwardRef={props.forwardRef}
                hideControls={hideControls}
                className="hidden xl:block"
                {...{ ...props, src: imageSet?.desktopImage || props.src }}
              />
              <ImageOrVideo
                forwardRef={props.forwardRef}
                hideControls={hideControls}
                className="hidden md:block xl:hidden"
                {...{ ...props, src: imageSet?.tabletImage || props.src }}
              />
              <ImageOrVideo
                forwardRef={props.forwardRef}
                hideControls={hideControls}
                className="block md:hidden"
                {...{ ...props, src: imageSet?.mobileImage || props.src }}
              />
            </>
          )}
    </>
  );
}
