import { Site } from '@apps/site';
import {
  GlobalPartsFragment,
  PageBlocksAnimationFactory,
  SettingsPartsFragment,
} from '@generated/types';
import { motion, useAnimation } from 'framer-motion';
import { useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { tinaField } from 'tinacms/dist/react';

export default function AnimationFactory({
  data,
  global,
  site
}: {
  data: PageBlocksAnimationFactory;
  global?: GlobalPartsFragment;
  settings?: SettingsPartsFragment;
  site: Site;
}) {
  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start({ opacity: 1 });
    }
  }, [controls, inView]);

  return (
    <section
      className="section-animation-factory w-screen h-screen flex items-center justify-center !mb-0 relative bg-center bg-cover p-0 content-start box-border flex-wrap overflow-hidden"
      data-tina-field={tinaField(data)}
    >
      {data?.headline && (
        <>
          <h2>
            <div>{data?.headline?.text}</div>
          </h2>
        </>
      )}

      <div className="relative w-full h-full">
        <img
          src={'/svg/technologystack_backg3.jpg'}
          alt="Technology Stack - How do we Operate"
          width={'100%'}
          height={'100%'}
          className="relative mix-blend-multiply -left-[-18%] w-[130%] md:w-full md:left-0"
        />
        {Array.from(Array(8).keys()).map((d: any, i: number) => (
          <motion.img
            ref={ref}
            animate={controls}
            initial={{ opacity: 0 }}
            transition={{
              delay: (i + 1) * 0.55,
              duration: 0.55 * 1.1,
            }}
            src={'/svg/0' + (i + 1) + '.svg'}
            alt={'Technology Stack - slide ' + (i + 1)}
            key={'child' + i}
            className="absolute top-0 md:left-0 md:w-full -left-[18%] w-[130%]"
          />
        ))}
      </div>
    </section>
  );
}

AnimationFactory.blockName = 'PageBlocksAnimationFactory';
