import { Site } from '@apps/site';
import {
  Maybe,
  PageBlocksGallery,
  PageBlocksGalleryImageEntries,
} from '@generated/types';
import ShapesComponent from '@libs/ui/shared/shapes';
import {
  getFontSizeClass,
  getFontWeightClass,
  getFontWeightClassFromData,
  getHeadlineTagFromData,
  transformToHeadlineParams,
} from '@libs/ui/utils/Templates/style';
import React, { useEffect, useState } from 'react';
import { tinaField } from 'tinacms/dist/react';
import SubmenuSingleIcon from '@libs/ui/utils/Templates/Shapes/SubmenuSingleIcon';
import { SafeLink } from '@libs/ui/utils/safe-link';

export default function Gallery({
  data,
  site,
}: {
  data: PageBlocksGallery;
  site: Site;
}) {
  const HeadlineTag = getHeadlineTagFromData(data?.headline);
  const headlineParams = transformToHeadlineParams(data?.headline);
  const fontWeightClass = getFontWeightClassFromData(data?.headline);
  const headlineFontSizesClass = getFontSizeClass(headlineParams);
  const bodyParams = transformToHeadlineParams(data?.body);
  const bodyFontWeightClass = getFontWeightClass(bodyParams);
  const bodyFontSizesClass = getFontSizeClass(bodyParams);

  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    if (window.innerWidth > 1024) {
      setIsMobile(false);
    }
    window.addEventListener('resize', () => {
      if (window.innerWidth > 1024) {
        setIsMobile(false);
      } else {
        setIsMobile(true);
      }
    });
  }, []);
  const boxShadow = data?.boxShadow;
  const boxShadowBig = boxShadow ? 'box-shadow-big' : '';
  const {
    fullHeight = false,
    marginTop = false,
    marginBottom = false,
  } = data || {};

  // Apply dynamic classes based on settings
  const containerClasses = `
    ${fullHeight ? 'fullscreen' : 'h-auto'}
    flex
    flex-col
    items-center
    justify-center
    ${!fullHeight && marginTop ? 'margin-top' : 'margin-top-mobile'}
    ${!fullHeight && marginBottom ? 'margin-bottom' : 'margin-bottom-mobile'}
  `;
  const renderImageGallery = () => {
    const textBlock = (
      <div
        key={`galleryEntry#textBlock`}
        className={`col-span-4 lg:col-span-2 xl:col-span-1 w-full pb-[40px] mb-[20px] lg:mb-0 lg:pb-0`}
        data-tina-field={tinaField(data)}
      >
        {data?.headline?.text && (
          <HeadlineTag
            className={`${fontWeightClass} ${headlineFontSizesClass} pb-[20px]`}
            data-tina-field={tinaField(data.headline, 'text')}
          >
            {data?.headline?.text.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            )) || ``}
          </HeadlineTag>
        )}
        {data?.body?.text && (
          <p
            className={`${bodyFontWeightClass} ${bodyFontSizesClass}`}
            data-tina-field={tinaField(data.body, 'text')}
          >
            {data?.body.text.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}
                <br />
              </React.Fragment>
            )) || ``}
          </p>
        )}
        {data?.linkName && data?.linkTarget && (
          <div className="flex items-center mt-4">
            <SubmenuSingleIcon
              style={{
                width: '20px',
                height: '20px',
                marginRight: '10px',
              }}
            />
            <SafeLink
              href={data.linkTarget}
              tina={tinaField(data, 'linkTarget')}
            >
              {data.linkName}
            </SafeLink>
          </div>
        )}
      </div>
    );

    // Check if both headline and body are empty
    if (!data?.headline?.text && !data?.body?.text) {
      return (
        <div className="container">
          <div
            className={`contentGrid grid pb-[50px] gap-y-4 xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap`}
          >
            {data.imageEntries?.map(
              (entry: Maybe<PageBlocksGalleryImageEntries>, i: number) => {
                if (
                  entry &&
                  entry.media &&
                  ((isMobile && entry.showImageOnMobile) || !isMobile)
                ) {
                  return (
                    <div
                      className={`col-span-4 lg:col-span-2 xl:col-span-1 w-full h-auto ${boxShadowBig} relative`}
                      key={`galleryEntry#${i}`}
                    >
                      <img
                        src={entry.media}
                        alt={`Gallery Image ${i + 1}`}
                        className={`w-full object-cover aspect-[${data.variant === 'Image 3:2' ? '3/2' : '1/1'}]`}
                      />
                    </div>
                  );
                }
                return null;
              },
            )}
          </div>

          {/* Shapes */}
          <div
            className="contentGrid grid gap-y-4 xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap "
            data-tina-field={tinaField(data, 'shapeOptions')}
          >
            <div className="flex justify-end md:col-start-2 col-start-4">
              <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
            </div>
          </div>
        </div>
      );
    }

    // Render image gallery with text block
    let imageEntries = data.imageEntries?.map(
      (entry: Maybe<PageBlocksGalleryImageEntries>, i: number) => {
        if (
          entry &&
          entry.media &&
          ((isMobile && entry.showImageOnMobile) || !isMobile)
        ) {
          return (
            <div
              className={`col-span-4 lg:col-span-2 xl:col-span-1 w-full h-auto mb-[20px] lg:mb-0 relative ${boxShadowBig}`}
              key={`galleryEntry#${i}`}
            >
              <img
                src={entry.media}
                alt={`Gallery Image ${i + 1}`}
                className={`w-full object-cover aspect-[${data.variant === 'Image 3:2' ? '3/2' : '1/1'}]`}
              />
            </div>
          );
        }
        return null;
      },
    );

    const index = isMobile ? 0 : 3;

    const entries = [
      ...(imageEntries?.slice(0, index) || []),
      textBlock,
      ...(imageEntries?.slice(index) || []),
    ];

    return (
      <div className="container">
        <div
          className={`contentGrid grid pb-[50px] gap-y-[20px] lg:gap-y-[40px]  xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap `}
        >
          {entries}
        </div>

        {/* Shapes */}
        <div
          className="contentGrid grid gap-y-4 xl:gap-y-custom-xl-gap hd:gap-y-custom-hd-gap 2k:gap-y-custom-2k-gap "
          data-tina-field={tinaField(data, 'shapeOptions')}
        >
          <div className="flex justify-end md:col-start-2 col-start-4">
            <ShapesComponent shapeOptions={data?.shapeOptions || {}} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <section
      className={`section-gallery w-full flex flex-col items-center justify-center ${containerClasses}`}
      data-tina-field={tinaField(data)}
    >
      {renderImageGallery()}
    </section>
  );
}

Gallery.blockName = 'PageBlocksGallery';
